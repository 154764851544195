<template>
  <div class="header">
    <div class="h_left">
      <el-button class="order_btn blue_bgc" size="small" icon="el-icon-circle-plus" @click="to_new_work_order">{{$t('main.text1')}}</el-button>
    </div>
    <div class="h_right">
      <!-- 切换语言 -->
      <el-popover
        v-if="user_name=='hbadmin'"
        popper-class="logout"
        placement="bottom"
        trigger="click">
        <div class="lang_box">
          <div @click="set_lang('ch')">CN</div>
          <div @click="set_lang('en')">EN</div>
        </div>
        <div slot="reference" class="lang_btn">
          <div v-if="lang=='ch'">CN</div>
          <div v-else-if="lang=='en'">EN</div>
        </div>
      </el-popover>

      <el-avatar :size="30" :src="user_icon"></el-avatar>
      <span class="u_name">{{ user_name }}</span>
      
      <el-popover
        popper-class="logout"
        placement="bottom"
        v-model="visible"
        trigger="click">
        <i class="el-icon-arrow-down" v-if="!visible" slot="reference"></i>
        <i class="el-icon-arrow-up" v-else slot="reference"></i>
        <div class="out_btn" @click="log_out_dlg=true">{{$t('main.text2')}}</div>
      </el-popover>

    </div>

    <!-- 退出登录 弹框 -->
    <el-dialog
      title=" "
      :visible.sync="log_out_dlg"
      width="500px"
      top="20%"
      center>
      <div class="dlg_body">{{$t('main.text3')}}</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="log_out" class="confirm_btn">{{$t('main.text4')}}</el-button>
        <el-button @click="log_out_dlg = false" class="cancel_btn">{{$t('main.text5')}}</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { get } from "@/api/api";
import crypto from "@/crypto/crypto";

export default {
  data() {
    return {
      user_icon:require("../../assets/img/u_icon.png"),
      user_name:"XXX",
      visible:false,
      log_out_dlg:false,
    }
  },
  beforeMount() {
    this.get_user_info();
  },
  beforeDestroy() {
    this.bus.$off("get_user_info");
  },
  mounted() {
    this.bus.$on("get_user_info",(v)=>{
      if(v){
        this.get_user_info();
      }
    });
  },
  methods:{
    log_out(){
      sessionStorage.clear();
      this.$router.push('/login');
    },
    to_new_work_order(){
      if(this.$route.path != '/new_work_order'){
        this.$router.push('/new_work_order');
      }
    },
    async get_user_info(){
      const res = await get();
      if(res.data.code!=200){
          return this.msg("error",res.data.msg);
      }
      this.user_name = res.data.data.username;
      sessionStorage.setItem("username", res.data.data.username);
      sessionStorage.setItem("role", res.data.data.role);
      sessionStorage.setItem("user_info", crypto.set(res.data.data));

    },
    set_lang(v) {
      sessionStorage.setItem("language", v);
      setTimeout(() => {
        location.reload();
      }, 50);
    },

  },
  watch:{
    $route(){
      this.get_user_info();
    }
  }


}
</script>

<style scoped>
.header{
  height: 100%;
  /* line-height: 80px; */
  background: #ebebeb;
  text-align: right;
  box-sizing: border-box;
  padding: 0 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.h_right{
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #333;
}
.u_name{
  margin: 0 20px 0 10px;
}
.h_right i{
  cursor: pointer;
}
.out_btn{
  text-align: center;
  cursor: pointer;
}
.lang_btn div{
  cursor: pointer;
  margin-right: 20px;
}
.lang_box div{
  cursor: pointer;
  margin: 10px 0;
  text-align: center;
}
</style>

<style>
.logout{
  min-width: 65px;
}
</style>
